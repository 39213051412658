<script setup>
import { useUniverseStore } from '@/stores/universe'
import { computed } from 'vue'

const emits = defineEmits(['input'])
const props = defineProps({
  value: Boolean,
  width: {
    type: Number,
    default: 400
  }
})

const { getSettingByPath } = useUniverseStore()

const iValue = computed({
  get () {
    return props.value
  },
  set (val) {
    emits('input', val)
  }
})
const logoFull = computed(() => getSettingByPath('theme.logoFullUrl', null))
const version = computed(() => {
  let value = window.env.VUE_APP_VERSION || ''
  if (window.env.VUE_APP_SHA) {
    value += ` (${window.env.VUE_APP_SHA.substring(0, 8)})`
  }
  return value
})
const versionApi = computed(() => {
  let value = versionSetting.value.version || ''
  if (versionSetting.value.sha) {
    value += ` (${versionSetting.value.sha.substring(0, 8)})`
  }
  return value
})
const versionSetting = computed(() => getSettingByPath('workplace_version') ?? null)
</script>

<template>
  <v-dialog
    v-model="iValue"
    :width="width"
    @click:outside="iValue = false"
  >
    <v-card data-test="card-about">
      <v-card-title class="justify-center">
        {{ $t('common.about') }}
      </v-card-title>
      <v-card-subtitle>
        <v-img
          :src="logoFull"
          height="110"
          contain
        />
      </v-card-subtitle>
      <v-card-text class="pb-0">
        <p
          data-test="middle-version"
          v-html="$t('common.middle-version', { version })"
        />
        <p
          data-test="api-version"
          v-html="$t('common.api-version', { version: versionApi })"
        />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          small
          text
          outlined
          data-test="close-about-dialog"
          @click="iValue = false"
        >
          {{ $t('common.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
