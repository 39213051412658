<script setup>
import { useLayout } from '@/compositions/layout'
import DialogTask from '@/components/Dialogs/DialogTask'
import TaskManager from '@/components/TaskManager'
import Snackbar from '@/components/Snackbar'
import Note from '@/components/Note'
import Notifications from '@/components/Notifications'
import Sticker from './Header/Sticker'
import Header from './Header'
import Footer from './Footer'
import MobileNavigation from './MobileNavigation'
import { useAuthStore } from '@/stores/auth'
import { useNoteStore } from '@/stores/note'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

const {
  appIsReady,
  drawer,
  drawerRight,
  drawerRightExpanded,
  footerColor,
  showNote,
  showNotification,
  widthDrawerRight,
  closeDrawerRight,
  drawerRightExpand,
  drawRightIsUp,
  openNotification
} = useLayout()

const userStore = useUserStore()
const noteStore = useNoteStore()
const authStore = useAuthStore()

const { user } = storeToRefs(authStore)
const { getNote } = storeToRefs(noteStore)
const { profile, menuItems } = storeToRefs(userStore)

const hasAdminMenuItems = computed(() => menuItems.value?.admin?.length > 0 ?? false)
</script>

<template>
  <v-app>
    <Header
      v-if="appIsReady && profile"
      v-model="drawer"
      :items="menuItems?.main ?? null"
      :profile="profile"
      :user="user"
      @click:notification="openNotification"
    />

    <mobile-navigation
      v-if="appIsReady && $vuetify.breakpoint.mobile"
      v-model="drawer"
      :items="menuItems?.main ?? null"
    />
    <v-navigation-drawer
      v-model="drawerRight"
      :width="widthDrawerRight"
      :clipped="$vuetify.breakpoint.mdAndUp"
      temporary
      right
      app
      disable-route-watcher
      @input="drawRightIsUp"
    >
      <notifications
        v-if="showNotification && user && profile"
        ref="notification"
        :is-expanded="drawerRightExpanded"
        :user-id="profile.userProfileId"
        :profile-type="profile.type"
        @close="closeDrawerRight"
        @expand="drawerRightExpand"
      />
      <note
        v-if="showNote && getNote"
        :note="getNote"
        @expand="drawerRightExpand"
        @close="closeDrawerRight"
      />
      <v-progress-circular
        v-if="showNote && !getNote"
        :size="50"
        :width="3"
        indeterminate
      />
    </v-navigation-drawer>

    <snackbar />

    <v-main>
      <div class="d-flex justify-end">
        <sticker v-if="user && hasAdminMenuItems" />
      </div>
      <slot />
    </v-main>
    <Footer
      v-if="appIsReady"
      :color="footerColor"
      data-test="footer"
    />
    <task-manager v-if="user" />
    <dialog-task />
    <global-events target="window" />
  </v-app>
</template>
